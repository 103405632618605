<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Documentos</h1>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <label for="obra" class="block text-sm font-medium">Buscar resíduo</label>
          <select v-model="busca" id="nome" name="nome" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="" >Todos</option>
              <option v-for="re in residuos" :key="re" :value="re" >{{ re }}</option>
          </select>
      </div> 
      <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <label for="obra" class="block text-sm font-medium">Obra</label>
          <select v-model="obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
          </select>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-1">
        <label for="dataInicio" class="block text-sm font-medium">Validade Início</label>
        <datepicker v-model="dataInicio" :language="ptBR" name="dataInicio" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-1">
        <label for="dataFim" class="block text-sm font-medium">Validade Final</label>
        <datepicker v-model="dataFim" :language="ptBR" name="dataFim" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro </label>
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
    </div>

    <a :href="`${api}/v1/${route}/zipdocumentos/busca?busca=${busca}&obra=${obra}&dataInicio=${dataInicio}&dataFim=${dataFim}&token=${jwt}`"  target= "_blank" rel="noreferrer" class="bg-blue-400 hover:bg-blue-500 focus:bg-blue-500 focus:shadow-sm focus:ring-4 text-white flex py-2 my-3 rounded-lg text-sm shadow-sm hover:shadow-md w-80 font-semibold justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
      </svg>
      Download documentos filtro 
    </a>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Arquivo
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Validade
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Resíduo
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Obra
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.originalname ? item.originalname : ''}}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div v-if="item.validade" class="text-sm text-gray-500">
                        {{item.validade | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.residuo ? item.residuo.nome : ''}}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.obra && item.obra ? item.obra.nome : ''}}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <a class="mr-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400" v-if="item.filename" target="_blank"
                     :href="`${api}/v1/${route}/doc/${item.filename}?token=${jwt}&mimetype=${item.mimetype}&original=${item.originalname}`"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import moment from 'moment';

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      ptBR: ptBR,
      busca: '',
      dataInicio: '',
      dataFim: '',
      obra: null,
      obras: [],
      route: 'residuos',
      list: [],
      perPage: 10,
      query: { page: 1, limit: 10, skip: 0  },
      total: 0,
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/listdocs`, { obra: this.obra, busca: this.busca, dataInicio: this.dataInicio, dataFim: this.dataFim, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      if (confirm("Tem certeza que deseja excluir essa empresa?")) {
        await this.$http.delete(`/v1/${this.route}/${id}`);
        this.$vToastify.success("Removido com sucesso!");
        this.start();
      }
    },

    updateFiltro(){

      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, obra: this.obra, busca: this.busca, dataInicio: moment(this.dataInicio).toISOString(), dataFim: moment(this.dataFim).toISOString() };
      this.$router.push({ path: `/documentos`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/documentos`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    await this.start();
  },
  async beforeMount() {
    const reqobras = await this.$http.post('/v1/obras/list');
    this.obras = reqobras.data.data;

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>